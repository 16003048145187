@use "sass:math";
@use "~@otto-ec/global-frontend-settings/gfs" as gfs;
@use "~@otto-ec/global-frontend-settings/scss/grid" as grid;


/*                   */

/*                           */
/*                                                                                                        */
/*                                                                                                             */
/*                                     */
/*                                                                                                             */
/*                                        */
/*                                                                                                  */

.subnavWrapper .subnav .breadcrumb {
  padding-left: 0;
  padding-right: 0;
}

/*                 */

.nav_grimm-breadcrumb-container {
  visibility: visible !important;

  position: relative;

  margin-left: 2 * grid.$inner-grid-padding;
  margin-right: 2 * grid.$inner-grid-padding;

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 10px;
  }

  /*       */
  /*       */

  &__breadcrumb {
    margin-bottom: 4px;
    @include gfs.breakpoint(gfs.$breakpoint-l) {
      padding-left: 2 * grid.$inner-grid-padding;
      margin-bottom: 16px;
    }

    &--product-page {
      margin-bottom: 0;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__title {
    @include gfs.breakpoint(gfs.$breakpoint-l) {
      padding-left: grid.$inner-grid-padding;
    }
  }

  /*        */
  /*        */

  &--product-page {
    padding-bottom: 0;
  }

  &--brandshop {
    @include gfs.breakpoint(gfs.$breakpoint-l) {
      padding-bottom: 0;
      height: unset !important;
    }
  }

  &--brandshop:has(.nav_grimm-refer-back--visible) {
    margin-left: 12px;
  }
}

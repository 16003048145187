@use "~@otto-ec/global-frontend-settings/gfs" as gfs;
@use "~@otto-ec/global-frontend-settings/scss/variables" as variables;



.nav_grimm-breadcrumb-headline {
  text-align: left;
  background-color: variables.$white;
  display: flex;

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    margin: 0;
  }

  &__text-group {
    flex-grow: 2;
    overflow: hidden;
    display: flex;

    @include gfs.breakpoint(gfs.$breakpoint-l) {
      text-align: left;
      align-items: baseline;
    }

    &--brandshop {
      margin-left: 16px;
      align-items: center;
    }
  }

  &__title {
    font-family: variables.$sansFontFamily;
    color: variables.$black100;
    font-weight: bold;
    @include gfs.font-size(variables.$font125, false);
    line-height: 32px;

    /*              */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include gfs.breakpoint(gfs.$breakpoint-l) {
      margin-right: 12px;
      display: inline-block;
      @include gfs.font-size(variables.$font225, false);
      line-height: normal;
    }
    &--nomargin{
      @include gfs.breakpoint(gfs.$breakpoint-l){
        margin-right: 0;
      }
    }
    &--clickable { /*          */
      cursor: pointer;

      @include gfs.breakpoint(gfs.$breakpoint-l){
        cursor: default;
      }
    }
  }

  &__quote {
    font-family: variables.$sansFontFamily;
    color: variables.$black100;
    font-weight: bold;
    @include gfs.font-size(variables.$font125, false);
    line-height: 32px;

    @include gfs.breakpoint(gfs.$breakpoint-l) {
      margin-right: 12px;
      display: inline-block;
      @include gfs.font-size(variables.$font225, false);
      line-height: normal;
    }
  }

  &__count {
    font-family: variables.$sansFontFamily;
    color: variables.$grey400;
    font-weight: normal;
    @include gfs.font-size(variables.$font100, false);

    @include gfs.breakpoint(gfs.$breakpoint-l) {
      flex: 0 0 135px;
    }
  }

  &__icon {
    flex-grow: 0;
    align-self: center;
    font-size: 22px;
    text-align: center;

    & .nav_grimm-refer-back {
      margin-right: 8px;
    }

    @include gfs.breakpoint(gfs.$breakpoint-l) {
      font-size: 24px;

      & .nav_grimm-refer-back {
        margin: 0;
      }
    }
  }

  &__brandshop-logo {
    height: 64px;
    width: 64px;
    border-radius: 32px;
    outline: thin #e6e6e6 solid;
    overflow: hidden;
    @include gfs.breakpoint(gfs.$breakpoint-l) {
      margin-left: 8px;
    }
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    & div {
      width: 46px;
      height: 44px;
      & img {
        object-fit: contain;
      }
    }
  }

  &--brandshop {
    margin: 12px 0;
  }
}

@use "~@otto-ec/global-frontend-settings/gfs" as gfs;
@use "~@otto-ec/global-frontend-settings/scss/variables" as variables;

.nav_grimm-refer-back {
    display: inline-block;
    cursor: pointer;
    @include gfs.font-size(variables.$font225, false);
    line-height: 22px;
    width: 24px;
    vertical-align: middle;
}

@use "~@otto-ec/global-frontend-settings/gfs" as gfs;
@use "~@otto-ec/global-frontend-settings/scss/variables" as variables;


.nav_grimm-breadcrumb {
  @include gfs.font-size(variables.$font75, false);
  color: variables.$grey400;
  background-color: variables.$white;
  line-height: 20px;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &__list-item {
    display: none;

    &:after {
      content: " |";
    }

    &:last-of-type:after {
      content: normal;
    }

    @include gfs.breakpoint(gfs.$breakpoint-l) {
      display: inline-block;
    }
  }

  &__always-visible-list-item {
    display: inline;
  }

  &__ellipsis-item {
    display: inline-block;
  }

  &__icon-item .nav_grimm-refer-back {
    margin-right: 16px;
  }

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    @include gfs.font-size(variables.$font100, false);
    line-height: 22px;

    &__ellipsis-item {
      display: none;
    }
  }

  &__link {
    text-decoration: none;
  }
  &--brandshop {
    @media(max-width: map-get(gfs.$breakpoints, gfs.$breakpoint-s)) {
      display: none;
    }
    @include gfs.breakpoint(gfs.$breakpoint-s, gfs.$breakpoint-l) {
      display: none;
    }
  }
}
